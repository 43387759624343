import { useState, useRef, useMemo, memo, useCallback } from 'react';
import { motion } from 'framer-motion';
import LazyImage from '../components/shared/LazyImage';
import FadeIn from '../components/shared/FadeIn';
import { 
  PhoneIcon, 
  MapPinIcon, 
  ClockIcon 
} from '@heroicons/react/24/outline';

const LocationCard = memo(({ location, index }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="h-48 relative overflow-hidden group">
        <LazyImage 
          src={location.image}
          alt={location.city}
          className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
        />
        <div className="absolute inset-0 bg-black/30" />
        <h3 className="absolute bottom-4 left-4 text-2xl font-bold text-white">
          {location.city}
        </h3>
      </div>
      <div className="p-6">
        <div className="space-y-4">
          <div className="flex items-start space-x-3">
            <MapPinIcon className="w-5 h-5 text-[#891F12] mt-1" />
            <p className="text-gray-600">{location.address}</p>
          </div>
          <div className="flex items-start space-x-3">
            <PhoneIcon className="w-5 h-5 text-[#891F12] mt-1" />
            <p className="text-gray-600">{location.phone}</p>
          </div>
          <div className="flex items-start space-x-3">
            <ClockIcon className="w-5 h-5 text-[#891F12] mt-1" />
            <p className="text-gray-600">{location.hours}</p>
          </div>
        </div>
      </div>
    </div>
  </FadeIn>
));

const FAQCard = memo(({ faq, index }) => (
  <FadeIn delay={index * 0.1}>
    <div className="bg-white p-6 rounded-xl shadow-lg hover:shadow-xl transition-shadow">
      <h3 className="text-xl font-bold text-[#891F12] mb-3">{faq.question}</h3>
      <p className="text-gray-600">{faq.answer}</p>
    </div>
  </FadeIn>
));

function Contact() {
  const contactFormRef = useRef(null);
  const [activeTab, setActiveTab] = useState('message');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
    subject: '',
    preferred: 'email',
    visitDate: '',
    location: ''
  });

  const locations = useMemo(() => [
    {
      city: "London",
      address: "2 Leicester Place, London",
      phone: "+44 74 4139 2787",
      hours: "Mon-Fri: 9:00 AM - 6:00 PM",
      image: "/images/London.jpeg"
    },
    {
      city: "Texas",
      address: "48 Beechnut St, Houston, TX 77096",
      phone: "713 399-0698",
      hours: "Mon-Fri: 9:00 AM - 6:00 PM",
      image: "/images/Texas.jpg"
    },
    {
      city: "Germany",
      address: "Fasanenstrasse 59, Hamburg Lemsahl-mellingstedt 22399",
      phone: "+49 831 80 35 54",
      hours: "Mon-Fri: 9:00 AM - 6:00 PM",
      image: "/images/Germany.jpg"
    }
  ], []);

  const faqs = useMemo(() => [
    {
      question: "What is the minimum investment required?",
      answer: "Our investment opportunities typically start from £50,000, with various portfolio options available to suit different investment goals."
    },
    {
      question: "How do you ensure artwork authenticity?",
      answer: "We work with leading authentication experts and maintain strict due diligence processes for all acquisitions."
    },
    {
      question: "What are your management fees?",
      answer: "Our fee structure is transparent and competitive, typically ranging from 1-2% annually based on portfolio size."
    },
    {
      question: "How can I liquidate my art investments?",
      answer: "We provide various exit strategies and maintain strong relationships with auction houses and private collectors."
    }
  ], []);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleSubmit = useCallback((e) => {
    e.preventDefault();
    console.log(formData);
    // Add your form submission logic here
  }, [formData]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section */}
      <section className="relative h-[40vh] bg-[#891F12] overflow-hidden">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 0.1 }}
          viewport={{ once: true }}
          className="absolute inset-0 grid grid-cols-8 grid-rows-8 gap-1"
        >
          {[...Array(64)].map((_, i) => (
            <div key={i} className="bg-white/10 rounded-lg" />
          ))}
        </motion.div>
        
        <FadeIn className="relative h-full flex items-center justify-center text-center z-10">
          <div className="text-white px-4">
            <h1 className="text-5xl font-bold mb-6">Contact Us</h1>
            <p className="text-xl text-gray-200 max-w-2xl mx-auto">
              Get in touch with our team of art investment experts
            </p>
          </div>
        </FadeIn>
      </section>

      {/* Form Section */}
      <section ref={contactFormRef} className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn>
          <div className="bg-white rounded-xl shadow-xl p-8">
            <div className="flex space-x-4 mb-8">
              <button
                onClick={() => setActiveTab('message')}
                className={`flex-1 py-3 rounded-lg font-medium ${
                  activeTab === 'message' 
                    ? 'bg-[#891F12] text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                Send Message
              </button>
              <button
                onClick={() => setActiveTab('visit')}
                className={`flex-1 py-3 rounded-lg font-medium ${
                  activeTab === 'visit' 
                    ? 'bg-[#891F12] text-white' 
                    : 'bg-gray-100 text-gray-600 hover:bg-gray-200'
                }`}
              >
                Schedule Visit
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid md:grid-cols-2 gap-6">
                <input
                  type="text"
                  name="name"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-[#891F12] focus:ring-1 focus:ring-[#891F12] outline-none"
                  required
                />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-[#891F12] focus:ring-1 focus:ring-[#891F12] outline-none"
                  required
                />
              </div>

              {activeTab === 'visit' && (
                <div className="grid md:grid-cols-2 gap-6">
                  <input
                    type="date"
                    name="visitDate"
                    value={formData.visitDate}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-[#891F12] focus:ring-1 focus:ring-[#891F12] outline-none"
                    required
                  />
                  <select
                    name="location"
                    value={formData.location}
                    onChange={handleInputChange}
                    className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-[#891F12] focus:ring-1 focus:ring-[#891F12] outline-none"
                    required
                  >
                    <option value="">Select Location</option>
                    {locations.map((location, index) => (
                      <option key={index} value={location.city}>
                        {location.city}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              <textarea
                name="message"
                placeholder="Your Message"
                value={formData.message}
                onChange={handleInputChange}
                rows="4"
                className="w-full px-4 py-3 rounded-lg border border-gray-300 focus:border-[#891F12] focus:ring-1 focus:ring-[#891F12] outline-none"
                required
              />

              <button
                type="submit"
                className="w-full bg-[#891F12] text-white py-3 rounded-lg font-medium hover:bg-[#761A0F] transition-colors"
              >
                {activeTab === 'message' ? 'Send Message' : 'Schedule Visit'}
              </button>
            </form>
          </div>
        </FadeIn>
      </section>

      {/* Locations Grid */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Locations</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Visit us at one of our global offices
          </p>
        </FadeIn>
        <div className="grid md:grid-cols-3 gap-8">
          {locations.map((location, index) => (
            <LocationCard key={index} location={location} index={index} />
          ))}
        </div>
      </section>

      {/* FAQ Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Frequently Asked Questions</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Find answers to common questions about our services
          </p>
        </FadeIn>
        <div className="grid md:grid-cols-2 gap-8">
          {faqs.map((faq, index) => (
            <FAQCard key={index} faq={faq} index={index} />
          ))}
        </div>
      </section>
    </div>
  );
}

export default memo(Contact);
