import { ArrowRightIcon, ChartBarIcon, UserGroupIcon, GlobeAltIcon, AcademicCapIcon, BriefcaseIcon, SparklesIcon, ShieldCheckIcon, PlayIcon } from '@heroicons/react/24/outline';
import { Link, useNavigate } from 'react-router-dom';
import { useState, useRef, useCallback, useMemo } from 'react';
import { memo } from 'react';

// Memoized components with enhanced design
const NewsletterSection = memo(() => (
  <section className="bg-gradient-to-br from-eagleton-300 to-eagleton-500 py-12 sm:py-20 px-6 relative overflow-hidden">
    <div className="absolute inset-0 opacity-10 bg-[radial-gradient(#ffffff_1px,transparent_1px)] [background-size:16px_16px]" />
    
    <div className="max-w-xl mx-auto text-center relative z-10">
      <h2 className="text-3xl sm:text-4xl font-extrabold text-white mb-4 sm:mb-6 animate-pulse tracking-tight">
        Subscribe to Our Newsletter
      </h2>
      <p className="text-white/80 mb-6 sm:mb-8 text-base sm:text-lg px-4">
        Unlock exclusive insights into art investments and emerging talents
      </p>
      <form className="flex flex-col sm:flex-row gap-3 max-w-md mx-auto px-4">
        <input
          type="email"
          placeholder="Enter your email"
          className="flex-1 px-4 py-3 rounded-full bg-white/20 border border-white/30
            text-white placeholder-white/70 focus:ring-2 focus:ring-white/50 transition-all"
        />
        <button className="bg-white text-eagleton-400 px-6 py-3 rounded-full 
          transition-transform active:scale-95 hover:scale-105 hover:bg-white/90 
          flex items-center justify-center group">
          Subscribe 
          <ArrowRightIcon className="h-5 w-5 ml-2 group-hover:translate-x-1 transition-transform" />
        </button>
      </form>
    </div>
  </section>
));

const InvestmentCard = memo(({ title, description, icon: Icon }) => (
  <div className="p-6 bg-white rounded-2xl shadow-lg hover:shadow-xl 
    transition-all duration-300 cursor-pointer group border-l-4 border-transparent 
    hover:border-eagleton-300 relative overflow-hidden">
    <div className="absolute inset-0 opacity-5 bg-gradient-to-br from-eagleton-200 to-eagleton-300 -z-10" />
    
    {Icon && (
      <div className="absolute top-4 right-4 opacity-10 group-hover:opacity-20 transition-opacity">
        <Icon className="h-12 w-12 sm:h-16 sm:w-16 text-eagleton-300" />
      </div>
    )}
    
    <h3 className="text-xl sm:text-2xl font-bold text-eagleton-300 mb-3 group-hover:text-eagleton-400
      transition-colors flex items-center">
      {title}
      <span className="ml-2 opacity-0 group-hover:opacity-100 transition-opacity">
        →
      </span>
    </h3>
    <p className="text-gray-600 group-hover:text-gray-900 transition-colors text-sm sm:text-base">
      {description}
    </p>
  </div>
));

function Home() {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  // Memoize video handler
  const handlePlayVideo = useCallback(() => {
    if (videoRef.current) {
      if (!isPlaying) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
      setIsPlaying(!isPlaying);
    }
  }, [isPlaying]);

  return (
    <div className="min-h-screen bg-eagleton-50 relative overflow-x-hidden">
      {/* Hero Section - Enhanced with Creative Layout */}
      {/* Hero Section */}
<section className="relative h-[80vh] sm:h-[60vh] md:h-[calc(100vh-100px)] overflow-hidden z-20">
  <div 
    className="absolute inset-0 transform transition-transform duration-1000 
      animate-pan bg-cover bg-center after:absolute after:inset-0 
      after:bg-gradient-to-r after:from-black/70 after:via-black/50 after:to-transparent"
    style={{
      backgroundImage: "url('https://images.unsplash.com/photo-1594732832278-abd644401426')",
    }}
  />
 <div className="relative z-10 h-full flex flex-col justify-center px-6 sm:px-8 
  max-w-xl mx-auto text-center sm:text-left">
          <div className="bg-black/30 backdrop-blur-sm p-6 sm:p-8 rounded-2xl shadow-2xl border border-white/20">
  <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold text-white mb-4 
    animate-fade-in tracking-tight leading-tight">
              Eagleton <br className="hidden md:block" />
              Art & Design
            </h1>
            <p className="text-white/80 mb-6 text-lg animate-fade-in max-w-md">
              Transforming art into strategic investments. Discover unique opportunities 
              in contemporary creativity.
            </p>
            <Link to="/contact" 
              className="inline-block bg-eagleton-400 text-white px-8 py-3 rounded-full
                hover:bg-eagleton-500 transition-colors animate-fade-in 
                hover:scale-105 shadow-lg hover:shadow-xl group">
              Join Today
              <ArrowRightIcon className="h-5 w-5 ml-2 inline-block 
                group-hover:translate-x-1 transition-transform" />
            </Link>
          </div>
        </div>

        {/* Creative Overlay Elements */}
        <div className="absolute bottom-0 left-0 right-0 h-24 bg-gradient-to-t from-eagleton-50 to-transparent z-10" />
      </section>
{/* About Studio & Registration Split */}
<section className="grid md:grid-cols-3 gap-6 p-6 sm:p-12 bg-white z-20 relative">
  <div className="bg-eagleton-300 text-white p-6 sm:p-8 md:col-span-2 rounded-xl shadow-lg">
    <h2 className="text-2xl sm:text-3xl font-bold mb-4">About Our Studio</h2>
    <div className="w-12 h-1 bg-eagleton-400 mb-6" />
    <p className="text-gray-200 text-sm sm:text-base">
      At Eagleton Art & Design, we curate exceptional art pieces and provide 
      unique investment opportunities. Our gallery represents both established 
      and emerging artists.
    </p>
  </div>
  <div className="bg-eagleton-400 text-white p-6 sm:p-8 rounded-xl shadow-lg">
    <h2 className="text-2xl sm:text-3xl font-bold mb-4">Registration Open</h2>
    <p className="text-gray-200 mb-4 text-sm sm:text-base">15 June - 30 July 2024</p>
    <p className="text-gray-200 mb-6 text-sm sm:text-base">Next Investment Round</p>
    <Link to="/investments" 
      className="inline-block bg-eagleton-500 text-white px-6 py-3 rounded-full
        hover:bg-eagleton-600 transition-colors active:scale-95">
      Enroll Now
    </Link>
  </div>
</section>

      {/* Workshops & Events Split */}
      <section className="grid md:grid-cols-2 gap-6 p-6 sm:p-12 bg-eagleton-50 z-20 relative">
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold text-eagleton-300 mb-4">
            Workshops & Events
          </h2>
          <div className="w-12 h-1 bg-eagleton-400 mb-6" />
          <p className="text-gray-600 mb-6">
            Join our exclusive events featuring renowned artists and investment experts.
            Learn about art appreciation and investment strategies.
          </p>
          <Link to="/events" 
            className="bg-eagleton-400 text-white px-6 py-2 rounded
              hover:bg-eagleton-500 transition-colors">
            View Events
          </Link>
        </div>
        <div className="relative h-[400px] bg-black">
          <video
            ref={videoRef}
            className="w-full h-full object-cover rounded-lg"
            src="/images/hero-video.mp4"
            playsInline
            loop
            muted
          >
            Your browser does not support the video tag.
          </video>
          
          {/* Play Button Overlay */}
          <div 
            className={`absolute inset-0 flex items-center justify-center 
              bg-black/30 transition-opacity duration-300 rounded-xl
              ${isPlaying ? 'opacity-0 hover:opacity-100' : 'opacity-100'}`}
            onClick={handlePlayVideo}
          >
            <button 
              className="w-16 h-16 bg-white/90 rounded-full flex items-center justify-center
                hover:bg-white transition-colors group"
            >
              <PlayIcon 
                className={`h-8 w-8 text-[#891F12] group-hover:scale-110 transition-transform
                  ${isPlaying ? 'translate-x-0.5' : 'translate-x-1'}`}
              />
            </button>
          </div>
        </div>
      </section>

      {/* Membership Split */}
      <section className="grid md:grid-cols-3 gap-8 p-12 bg-eagleton-50 z-20 relative">
        <div className="relative h-[400px] md:col-span-2">
          <img 
            loading="lazy"
            src="/images/Gallery-space.jpg"
            alt="Eagleton Gallery Space"
            className="w-full h-full object-cover rounded-[36px]"
          />
        </div>
        <div className="bg-eagleton-300 text-white p-8 rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4">Studio Membership</h2>
          <div className="w-12 h-1 bg-eagleton-400 mb-6" />
          <p className="text-gray-200 mb-6">
            Become a member to access exclusive investment opportunities, private viewings,
            and expert consultations. Join our community of art enthusiasts and investors.
          </p>
          <Link to="/contact" 
            className="bg-eagleton-400 text-white px-6 py-2 rounded
              hover:bg-eagleton-500 transition-colors">
            Become a Member
          </Link>
        </div>
      </section>

      {/* Join Our Cause */}
      <div className="bg-eagleton-50 grid md:grid-cols-3 p-12 z-20 relative">
        {/* Left Image Section */}
        <div className="relative h-[300px] overflow-hidden md:col-span-1 mb-6 md:mb-0">
          <img
            src="/images/Rennita-Eagleton.jpeg"
            alt="CEO"
            className="w-full h-full object-cover object-top rounded-[36px]"
          />
        </div>

        {/* Right Content Section */}
        <div className="bg-eagleton-50 text-white p-6 flex flex-col justify-center md:col-span-2">
          <h2 className="text-4xl font-bold mb-6 text-eagleton-300">
            JOIN OUR<br />CAUSE
          </h2>
          
          <p className="text-lg mb-6 text-gray-600">
            Join Our Cause at Eagleton Art and Design: Igniting Creativity, Inspiring 
            Change. At Eagleton Art and Design, we believe in the transformative power 
            of art and design to shape the world around us. Join us in our mission to push 
            boundaries, challenge norms, and create a lasting impact through creativity.
          </p>

          <Link to="/what-we-do"
            className="bg-eagleton-400 text-white px-6 py-2 rounded-lg text-lg 
              hover:bg-eagleton-500 transition-colors w-fit">
            Get Involved
          </Link>
        </div>
      </div>

      {/* Investment Opportunities - Enhanced */}
      <section className="py-20 px-8 bg-[rgb(245,230,211)] relative">
        <div className="absolute inset-0 opacity-5 bg-[radial-gradient(#000000_1px,transparent_1px)] [background-size:16px_16px]" />
        <div className="max-w-7xl mx-auto relative z-10">
          <h2 className="text-4xl font-bold text-center text-eagleton-300 mb-2 tracking-tight">
            Investment Opportunities
          </h2>
          <p className="text-center text-gray-600 mb-12">
            Discover curated art investments with proven track records
          </p>

          {/* Investment Cards */}
          <div className="grid md:grid-cols-3 gap-8">
            <InvestmentCard 
              title="Emerging Artists"
              description="Early access to promising artists with significant growth potential."
              icon={SparklesIcon}
            />
            <InvestmentCard 
              title="Established Masters"
              description="Stable investments in recognized artists with proven market value."
              icon={UserGroupIcon}
            />
            <InvestmentCard 
              title="Art Funds"
              description="Diversified portfolios managed by art market experts."
              icon={ChartBarIcon}
            />
          </div>
        </div>
      </section>

      {/* Exhibition Program */}
      <section className="grid md:grid-cols-2 gap-8 p-12 bg-eagleton-50 z-20 relative">
        <div className="p-8 bg-eagleton-300 text-white rounded-lg shadow-lg">
          <h2 className="text-3xl font-bold mb-4">Exhibition Program</h2>
          <div className="w-12 h-1 bg-eagleton-400 mb-6" />
          <p className="text-gray-200 mb-6">
            Experience our carefully curated exhibitions featuring both emerging talents
            and established artists. Our program reflects current market trends and
            future investment opportunities.
          </p>
          <Link to="/events"
            className="bg-eagleton-400 text-white px-6 py-2 rounded
              hover:bg-eagleton-500 transition-colors">
            View Schedule
          </Link>
        </div>
        <div className="relative h-[400px]">
          <img 
            src="/images/hero-bg-v2.jpg"
            alt="Exhibition Space"
            className="w-full h-full object-cover rounded-lg"
          />
        </div>
      </section>

      {/* Expert Advisory */}
      <section className="bg-[#FAF9F6] py-20 px-8 z-20 relative">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-center text-eagleton-300 mb-2">
            Expert Advisory Services
          </h2>
          <p className="text-center text-gray-600 mb-12">
            Personalized guidance for your art investment journey
          </p>
          <div className="grid md:grid-cols-4 gap-8">
            <div className="text-center">
              <div className="w-16 h-16 bg-eagleton-400 rounded-full mx-auto mb-4 flex items-center justify-center">
                <ChartBarIcon className="h-8 w-8 text-white" />
              </div>
              <h3 className="font-bold text-eagleton-300">Market Analysis</h3>
            </div>
            <div className="text-center">
              <div className="w-16 h-16 bg-eagleton-400 rounded-full mx-auto mb-4 flex items-center justify-center">
                <BriefcaseIcon className="h-8 w-8 text-white" />
              </div>
              <h3 className="font-bold text-eagleton-300">Portfolio Management</h3>
            </div>
            <div className="text-center">
              <div className="w-16 h-16 bg-eagleton-400 rounded-full mx-auto mb-4 flex items-center justify-center">
                <AcademicCapIcon className="h-8 w-8 text-white" />
              </div>
              <h3 className="font-bold text-eagleton-300">Education</h3>
            </div>
            <div className="text-center">
              <div className="w-16 h-16 bg-eagleton-400 rounded-full mx-auto mb-4 flex items-center justify-center">
                <ShieldCheckIcon className="h-8 w-8 text-white" />
              </div>
              <h3 className="font-bold text-eagleton-300">Authentication</h3>
            </div>
          </div>
        </div>
      </section>

      {/* Global Network */}
      <section className="grid md:grid-cols-3 p-12 bg-white z-20 relative mb-8">
        <div className="bg-eagleton-300 text-white p-8 md:col-span-2 rounded-lg shadow-lg mr-4">
          <h2 className="text-3xl font-bold mb-4">Global Network</h2>
          <div className="w-12 h-1 bg-eagleton-400 mb-6" />
          <p className="text-gray-200">
            Access our extensive network of galleries, collectors, and art professionals
            worldwide. Our connections span major art markets including London, New York,
            Hong Kong, and Paris, providing unique opportunities for our clients.
          </p>
        </div>
        <div className="bg-eagleton-400 text-white p-8 rounded-lg shadow-lg ml-4">
          <h2 className="text-3xl font-bold mb-4">Partner With Us</h2>
          <p className="text-gray-200 mb-6">Join our network of art world professionals</p>
          <Link to="/who-we-are"
            className="bg-eagleton-500 text-white px-6 py-2 rounded
              hover:bg-eagleton-600 transition-colors">
            Learn More
          </Link>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="relative py-20">
        <div 
          className="absolute inset-0"
          style={{
            backgroundImage: "url('/images/eagle.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="absolute inset-0 bg-black/70" />
        </div>
        <div className="relative z-10 max-w-xl mx-auto px-4">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-3xl font-bold text-eagleton-300 mb-6">
              From Our Collectors
            </h2>
            <div className="w-12 h-1 bg-eagleton-400 mb-6" />
            <p className="text-gray-600 italic mb-4">
              "Working with Eagleton Art & Design has transformed my understanding of art investment.
              Their expertise and guidance have been invaluable."
            </p>
            <p className="text-eagleton-400 font-bold">- Sarah Mitchell</p>
          </div>
        </div>
      </section>

      {/* Use NewsletterSection at the bottom */}
      <NewsletterSection />
    </div>
  );
}

export default memo(Home);
