import { useMemo, memo, useCallback, Suspense, lazy } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import LazyImage from '../components/shared/LazyImage';
import FadeIn from '../components/shared/FadeIn';

// Lazy load the TeamMemberCard component
const TeamMemberCard = lazy(() => import('../components/who-we-are/TeamMemberCard'));

function WhoWeAre() {
  const navigate = useNavigate();

  const handleNavigation = useCallback((path) => {
    navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [navigate]);

  const teamMembers = useMemo(() => [
    {
      name: "Richard Bowman",
      position: "President",
      description: "Leading our organization with over 25 years of experience in art investment and market strategy, Richard brings visionary leadership and deep industry expertise.",
      image: "/images/Richard Bowman.jpg"
    },
    {
      name: "Renita Eagleton",
      position: "Vice President",
      description: "With over 20 years of experience in art market analysis and portfolio management, Rennita drives our strategic vision with unparalleled expertise in both contemporary and classical art markets.",
      image: "/images/Rennita-Eagleton.jpeg"
    },
    {
      name: "Jeffrey Sellers",
      position: "Chief Financial Officer",
      description: "Oversees financial operations and investment planning with deep expertise in art market economics and portfolio optimization.",
      image: "/images/Jeffery Sellers.jpg"
    },
    {
      name: "Heike Wittreck",
      position: "Director of Payroll",
      description: "Managing financial operations and employee services with precision and expertise, ensuring seamless organizational operations.",
      image: "/images/Heike Wittreck.jpg"
    },
    {
      name: "Therese Steinlauf",
      position: "Director of Client Relations",
      description: "Oversees client portfolio management and relationship development, providing personalized service to our global investor base.",
      image: "/images/Therese Steinlauf.jpg"
    }
  ], []);

  const milestones = useMemo(() => [
    {
      year: "2008",
      title: "Foundation",
      description: "Established with a vision to democratize art investment"
    },
    {
      year: "2013",
      title: "Global Expansion",
      description: "Opened offices in major art markets worldwide"
    },
    {
      year: "2017",
      title: "Digital Innovation",
      description: "Launched proprietary art market analytics platform"
    },
    {
      year: "2023",
      title: "Industry Leadership",
      description: "Recognized as leading art investment advisory firm"
    }
  ], []);

  const stats = useMemo(() => [
    { number: "£50M+", label: "Assets Under Management" },
    { number: "15+", label: "Years Experience" },
    { number: "300+", label: "Active Portfolios" },
    { number: "25%", label: "Average Annual Return" }
  ], []);

  const coreValues = useMemo(() => [
    {
      title: "Excellence",
      description: "We maintain the highest standards in art investment services, continuously striving to exceed client expectations through rigorous analysis and strategic portfolio management."
    },
    {
      title: "Integrity",
      description: "Operating with complete transparency and ethical principles, we ensure our clients' interests are always at the forefront of our decision-making process."
    },
    {
      title: "Innovation",
      description: "Embracing new technologies and market opportunities, we pioneer advanced approaches to art investment while maintaining respect for traditional art market principles."
    },
    {
      title: "Education",
      description: "We believe in empowering our clients through knowledge, providing comprehensive insights into the art market and investment strategies."
    }
  ], []);

  const partnerships = useMemo(() => [
    {
      name: "Sotheby's",
      type: "Auction House",
      description: "Preferred access to major auctions and private sales",
      logo: (
        <svg viewBox="0 0 200 50" className="w-full h-full">
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            className="font-serif text-[#1A1A1A]"
            style={{ fontSize: '24px' }}
          >
            SOTHEBY'S
          </text>
        </svg>
      )
    },
    {
      name: "Christie's",
      type: "Auction House",
      description: "Strategic partnership for exclusive art acquisitions",
      logo: (
        <svg viewBox="0 0 200 50" className="w-full h-full">
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            className="font-serif text-[#CC0000]"
            style={{ fontSize: '24px' }}
          >
            CHRISTIE'S
          </text>
        </svg>
      )
    },
    {
      name: "Art Basel",
      type: "Art Fair",
      description: "VIP access and early viewing privileges",
      logo: (
        <svg viewBox="0 0 200 50" className="w-full h-full">
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            className="font-sans font-bold text-[#000000]"
            style={{ fontSize: '24px' }}
          >
            ART BASEL
          </text>
        </svg>
      )
    }
  ], []);

  const certifications = useMemo(() => [
    {
      title: "ISO 9001:2015",
      description: "Certified for quality management systems"
    },
    {
      title: "FCA Regulated",
      description: "Authorized and regulated by the Financial Conduct Authority"
    },
    {
      title: "USPAP Compliant",
      description: "Adherent to Uniform Standards of Professional Appraisal Practice"
    }
  ], []);

  const locations = useMemo(() => [
    {
      city: "London",
      address: "2 Leicester Place, London",
      image: "/images/London.jpeg"
    },
    {
      city: "Texas",
      address: "48 Beechnut St, Houston, TX 77096",
      image: "/images/Texas.jpg"
    },
    {
      city: "Germany",
      address: "Fasanenstrasse 59, Hamburg Lemsahl-mellingstedt 22399",
      image: "/images/Germany.jpg"
    }
  ], []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      {/* Hero Section with Background Image */}
      <section className="relative h-[60vh] bg-cover bg-center" style={{ backgroundImage: 'url(/images/h-image.jpg)' }}>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="absolute inset-0 flex items-center justify-center text-center text-white bg-black bg-opacity-60"
        >
          <h1 className="text-6xl font-bold mb-4 drop-shadow-lg">Who We Are</h1>
          <p className="text-xl max-w-2xl mx-auto">A team of dedicated art investment professionals</p>
        </motion.div>
      </section>

      {/* Stats Section */}
      <section className="relative z-10 max-w-7xl mx-auto px-4 -mt-16">
        <FadeIn>
          <div className="bg-white rounded-xl shadow-xl p-8 grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center">
                <h3 className="text-3xl font-bold text-[#891F12]">{stat.number}</h3>
                <p className="text-gray-600">{stat.label}</p>
              </div>
            ))}
          </div>
        </FadeIn>
      </section>

      {/* Enhanced Reviews Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-900 mb-6">What Our Clients Say</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">Hear from our satisfied clients about their experiences with our team</p>
        </FadeIn>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {[
            {
              name: "Elisha Turner",
              review: "Renita Eagleton's expertise in portfolio management is unmatched. I feel confident in my investments thanks to her guidance.",
            },
            {
              name: "Jared Thornton",
              review: "Working with Richard Bowman has transformed my understanding of art investment. His insights are invaluable!",
            },
            {
              name: "Khloe Barajas",
              review: "The team at Eagleton Arts has been incredibly supportive and knowledgeable. I highly recommend their services!",
            },
            {
              name: "Estella Copeland",
              review: "Thanks to Renita, I now have a well-rounded art portfolio that I am proud of. Her dedication is truly commendable.",
            },
            {
              name: "Brennan Frank",
              review: "Richard's visionary leadership has made a significant impact on my investment journey. I couldn't ask for a better partner.",
            },
            {
              name: "Axl Vargas",
              review: "The guidance from Sarah Thompson has been instrumental in my art investment success. I appreciate her insights!",
            },
          ].sort(() => Math.random() - 0.5).map((review, index) => (
            <FadeIn key={index} delay={index * 0.1}>
              <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col justify-between h-64 transition-transform transform hover:scale-105 hover:shadow-2xl">
                <h3 className="text-lg font-bold text-[#891F12] mb-2">{review.name}</h3>
                <p className="text-gray-600 flex-grow">{review.review}</p>
              </div>
            </FadeIn>
          ))}
        </div>
      </section>

      {/* Team Section */}
      <section className="max-w-6xl mx-auto px-4 py-16">
        <FadeIn className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Leadership Team</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Expert professionals dedicated to maximizing your art investment potential
          </p>
        </FadeIn>
        
        {/* Custom grid for 5 members */}
        <div className="grid gap-6">
          {/* Leadership Row (President & Vice President) */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 max-w-2xl mx-auto">
            {teamMembers.slice(0, 2).map((member, index) => (
              <Suspense key={index} fallback={<div className="aspect-[3/4] bg-gray-100 rounded-xl animate-pulse" />}>
                <FadeIn delay={index * 0.1}>
                  <TeamMemberCard member={member} />
                </FadeIn>
              </Suspense>
            ))}
          </div>
          
          {/* Other Executives Row */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-4xl mx-auto">
            {teamMembers.slice(2).map((member, index) => (
              <Suspense key={index + 2} fallback={<div className="aspect-[3/4] bg-gray-100 rounded-xl animate-pulse" />}>
                <FadeIn delay={(index + 2) * 0.1}>
                  <TeamMemberCard member={member} />
                </FadeIn>
              </Suspense>
            ))}
          </div>
        </div>
      </section>

      {/* Core Values Section */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Core Values</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              The principles that guide our service and shape our culture
            </p>
          </FadeIn>
          <div className="grid md:grid-cols-2 gap-8">
            {coreValues.map((value, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow transform hover:scale-105">
                  <h3 className="text-xl font-bold text-[#891F12] mb-4">{value.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{value.description}</p>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {/* Milestones Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Our Journey</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Key milestones in our history of excellence
          </p>
        </FadeIn>
        <div className="grid md:grid-cols-4 gap-8">
          {milestones.map((milestone, index) => (
            <FadeIn key={index} delay={index * 0.1}>
              <div className="bg-white p-8 rounded-xl shadow-lg text-center">
                <span className="text-3xl font-bold text-[#891F12] block mb-4">{milestone.year}</span>
                <h3 className="text-xl font-bold text-gray-900 mb-2">{milestone.title}</h3>
                <p className="text-gray-600">{milestone.description}</p>
              </div>
            </FadeIn>
          ))}
        </div>
      </section>

      {/* Strategic Partnerships */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Strategic Partnerships</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Collaborating with leading institutions in the art world
            </p>
          </FadeIn>
          <div className="grid md:grid-cols-3 gap-8">
            {partnerships.map((partner, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="bg-white p-8 rounded-xl shadow-lg text-center">
                  <div className="h-20 flex items-center justify-center mb-6">
                    {partner.logo}
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{partner.name}</h3>
                  <p className="text-[#891F12] font-medium mb-2">{partner.type}</p>
                  <p className="text-gray-600">{partner.description}</p>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {/* Global Presence */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 mb-4">Global Presence</h2>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Serving clients worldwide from our strategic locations
          </p>
        </FadeIn>
        <div className="grid md:grid-cols-3 gap-8">
          {locations.map((location, index) => (
            <FadeIn key={index} delay={index * 0.1}>
              <div className="bg-white rounded-xl shadow-lg overflow-hidden">
                <div className="h-48 relative">
                  <LazyImage 
                    src={location.image}
                    alt={location.city}
                    className="w-full h-full object-cover"
                  />
                  <div className="absolute inset-0 bg-black/30" />
                  <h3 className="absolute bottom-4 left-4 text-2xl font-bold text-white">
                    {location.city}
                  </h3>
                </div>
                <div className="p-6">
                  <p className="text-gray-600">{location.address}</p>
                </div>
              </div>
            </FadeIn>
          ))}
        </div>
      </section>

      {/* Certifications and Compliance */}
      <section className="bg-gray-50 py-20">
        <div className="max-w-7xl mx-auto px-4">
          <FadeIn className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900 mb-4">Certifications & Compliance</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Meeting the highest standards in the industry
            </p>
          </FadeIn>
          <div className="grid md:grid-cols-3 gap-8">
            {certifications.map((cert, index) => (
              <FadeIn key={index} delay={index * 0.1}>
                <div className="bg-white p-8 rounded-xl shadow-lg text-center">
                  <div className="w-16 h-16 bg-[#891F12]/10 rounded-full flex items-center justify-center mx-auto mb-6">
                    <svg className="w-8 h-8 text-[#891F12]" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                  </div>
                  <h3 className="text-xl font-bold text-gray-900 mb-2">{cert.title}</h3>
                  <p className="text-gray-600">{cert.description}</p>
                </div>
              </FadeIn>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="max-w-7xl mx-auto px-4 py-20">
        <FadeIn className="bg-[#891F12] text-white rounded-xl p-12 text-center shadow-lg hover:shadow-2xl transition-shadow">
          <h2 className="text-3xl font-bold mb-4">Join Our Journey</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Partner with us to explore the exciting world of art investment
          </p>
          <button 
            onClick={() => handleNavigation('/contact')}
            className="bg-white text-[#891F12] px-8 py-3 rounded-lg hover:bg-gray-100 
              transition-colors font-medium inline-flex items-center space-x-2"
          >
            <span>Contact Us</span>
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              className="h-5 w-5" 
              viewBox="0 0 20 20" 
              fill="currentColor"
            >
              <path 
                fillRule="evenodd" 
                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" 
                clipRule="evenodd" 
              />
            </svg>
          </button>
        </FadeIn>
      </section>
    </div>
  );
}

export default memo(WhoWeAre);
